<template>
  <div class="container-fluid p-4">
    <div class="d-flex justify-content-between">
      <h1>Manage Visit</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
      </div>
    </div>
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column">
        <!-- search visitor -->
        <b-card class="bg-surface-2">
          <b-row no-gutters>
            <b-col lg="6">
              <date-range-picker
                ref="picker"
                class="w-100"
                v-model="searchForm.dateRange"
                :locale-data="{
                  firstDay: 1,
                  format: 'yyyy-mm-dd HH:mm:ss'
                }"
                :time-picker-increment="1"
                :timePicker="true"
                :time-picker-seconds="true"
              >
                <template v-slot:input="picker">
                  {{ picker.startDate | dateTime }} -
                  {{ picker.endDate | dateTime }}
                </template>
              </date-range-picker>
            </b-col>
            <b-col lg="6">
              <b-input-group class="mb-1">
                <b-form-input
                  class="text-truncate"
                  id="input-1"
                  v-model="$v.searchForm.keyword.$model"
                  placeholder="Search by visit code, host name or visitor name"
                  :state="validateFormSearch('keyword')"
                  aria-describedby="searchForm.visit_code_feedback"
                  v-on:keyup.enter="getSearchData"
                  autocomplete="off"
                ></b-form-input>
                <b-form-invalid-feedback
                  class="invalid-feedback"
                  id="searchForm.visit_code_feedback"
                >
                  Your search must be at least 3 character.
                </b-form-invalid-feedback>
              </b-input-group>
            </b-col>
            <b-col lg="6">
              <multiselect
                placeholder="Choose visitor status"
                v-model="searchForm.status"
                label="value"
                track-by="key"
                :options="options_visit_status"
                :multiple="true"
              ></multiselect>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end mt-1">
            <b-button size="sm" variant="dark" @click="resetSearch" class="mr-1"
              >Clear
            </b-button>
            <b-button size="sm" variant="light" @click="getSearchData">
              Search
            </b-button>
          </div>
        </b-card>
        <div class="d-flex pt-4 pb-2">
          <div class="d-flex justify-content-start">
            <b-dropdown
              v-if="selected.length > 0"
              :text="selected.length + ' is selected'"
              size="sm"
              class="mr-1"
            >
              <b-dropdown-item @click="selectAllRows"
                >Select All
              </b-dropdown-item>
              <b-dropdown-item @click="clearSelected"
                >Clear Selections
              </b-dropdown-item>
              <b-dropdown-item @click="setCheckOut">Check Out </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="d-flex justify-content-start">
            <router-link
              :to="{ name: 'create-visit' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-button
                class="textColor"
                variant="primary"
                size="sm"
                :href="href"
                @click="navigate"
              >
                <i class="fas fa-plus mr-1"></i>Register New Visit
              </b-button>
            </router-link>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <!-- Main table element -->
          <b-table
            stacked="md"
            class="tableBorder"
            id="selectableTable"
            ref="selectableTable"
            small
            striped
            responsive
            show-empty
            selectable
            :busy="busy"
            :items="visitList"
            :fields="visitTableFields"
            :filter="searchTerms"
            :filter-function="filterTable"
            :select-mode="selectMode"
            @filtered="onFiltered"
            @row-selected="onRowSelected"
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(index)="row">
              {{ row.index + 1 + (currentPage - 1) * perPage }}
            </template>

            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>

            <template #cell(visit_code)="row">
              <router-link
                :to="{
                  name: 'visit-detail',
                  params: {
                    id: row.item.id
                  },
                  query: { parents_url: 'visits' }
                }"
                v-slot="{ href, route, navigate }"
                custom
              >
                <a :href="href" @click="navigate" class="mr-2">
                  <u> {{ row.item.visit_code }}</u></a
                >
              </router-link>
            </template>
            <template #cell(hosts)="row">
              <h6 v-for="host in row.item.hosts" :key="'_' + host.company_id">
                <i class="far fa-building mr-1"></i>
                <strong class="company-font">{{
                  host?.company?.name | humanizeText
                }}</strong>
              </h6>
              <h6 v-for="host in row.item.hosts" :key="'_' + host.id">
                <i class="fas fa-user-alt mr-1"></i
                ><strong class="host-font">{{
                  host.name | humanizeText
                }}</strong>
              </h6>
            </template>
            <template #cell(start_date_time)="row">
              <div>
                {{ row.item.visit_start_date | date }}
                -
                {{ row.item.visit_start_time | unixToTime12Hours }}
              </div>
            </template>
            <!--            <template #cell(end_date_time)="row">-->
            <!--              <div>-->
            <!--                {{ row.item.visit_end_date | date }} - -->
            <!--                {{ row.item.visit_end_time }}-->
            <!--              </div>-->
            <!--            </template>-->
            <template #cell(visitors)="row">
              <div class="d-flex flex-column align-items-start">
                <h6
                  v-for="visitor in row.item.visitors"
                  :key="'_' + visitor.id"
                >
                  <i class="fas fa-user-alt mr-1"></i>
                  <strong style="font-size: var(--font-size-normal)">
                    {{ visitor.name | humanizeText }}
                  </strong>
                </h6>
              </div>
            </template>

            <template #cell(status)="row">
              <h6>
                <b-badge
                  class="p-2"
                  style="width: 6rem"
                  :variant="
                    row.item.status === 'COMPLETED'
                      ? 'success'
                      : row.item.status === 'PENDING'
                      ? 'warning'
                      : 'secondary'
                  "
                >
                  <strong class="font">{{ row.item.status }}</strong>
                </b-badge>
              </h6>
            </template>

            <template #cell(additional_metadata.check_in_time)="row">
              <div v-if="row.item.additional_metadata.check_in_time != null">
                {{
                  row.item.additional_metadata.check_in_time | unixToDateTime
                }}
              </div>
              <div v-else>-</div>
            </template>
            <template #cell(actions)="row">
              <b-button-group size="sm">
                <!-- <b-button
                  size="sm"
                  @click="row.toggleDetails"
                  class="mr-1"
                  v-b-tooltip.hover
                  title="View Details"
                >
                  <i v-if="row.detailsShowing" class="fas fa-eye-slash"></i>
                  <i v-else class="fas fa-eye"></i>
                </b-button> -->
                <!-- <router-link
                  :to="{
                    name: 'visit-detail',
                    params: {
                      id: row.item.id
                    },
                    query: { parents_url: 'visits' }
                  }"
                  v-slot="{ href, route, navigate }"
                  custom
                >
                  <b-button
                    class="mr-1"
                    :href="href"
                    @click="navigate"
                    variant="primary"
                    size="sm"
                    v-b-tooltip.hover
                    title="Visit Action"
                  >
                    <i class="fas fa-running"></i>
                  </b-button>
                </router-link> -->

                <!-- <b-button
                  v-if="hasCheckIn(row.item) && !hasCheckOut(row.item)"
                  size="sm"
                  v-b-modal.form-update-user
                  v-b-tooltip.hover
                  title="Revert Check In"
                  @click="revertCheckIn(row.item)"
                  class="mr-1"
                >
                  <i class="fas fa-undo-alt"></i>
                </b-button> -->

                <!-- <b-button
                  v-if="!hasCheckIn(row.item) && !hasCheckOut(row.item)"
                  size="sm"
                  v-b-modal.form-update-user
                  v-b-tooltip.hover
                  title="Check In"
                  @click="handleCheckIn(row.item)"
                  class="mr-1"
                >
                  <i class="fas fa-user-check"></i>
                </b-button> -->

                <!--              <b-button-->
                <!--                v-if="hasCheckIn(row.item) && hasCheckOut(row.item)"-->
                <!--                size="sm"-->
                <!--                v-b-modal.form-update-user-->
                <!--                v-b-tooltip.hover-->
                <!--                title="Revert Check Out"-->
                <!--                @click="revertCheckOut(row.item)"-->
                <!--                class="mr-1"-->
                <!--              >-->
                <!--                <i class="fas fa-undo-alt"></i>-->
                <!--              </b-button>-->

                <b-button
                  v-if="
                    hasCheckIn(row.item) &&
                    !hasCheckOut(row.item) &&
                    (row.item.status == 'APPROVED' ||
                      row.item.status == 'EXPIRED')
                  "
                  variant="primary"
                  v-b-modal.form-update-user
                  v-b-tooltip.hover
                  title="Check Out"
                  @click="handleCheckOut(row.item)"
                  class="mr-1"
                >
                  <i class="fas fa-sign-out-alt"></i>
                </b-button>
                <b-button
                  v-else
                  disabled
                  variant="primary"
                  v-b-modal.form-update-user
                  v-b-tooltip.hover
                  title="Check Out"
                  @click="handleCheckOut(row.item)"
                  class="mr-1"
                >
                  <i class="fas fa-sign-out-alt"></i>
                </b-button>
              </b-button-group>
              <!--              <b-button-->
              <!--                  size="sm"-->
              <!--                  @click="row.toggleDetails"-->
              <!--                  class="mr-1"-->
              <!--                  v-b-tooltip.hover-->
              <!--                  title="View Details"-->
              <!--              >-->
              <!--                <i v-if="row.detailsShowing" class="fas fa-eye-slash"></i>-->
              <!--                <i v-else class="fas fa-eye"></i>-->
              <!--              </b-button>-->
              <!--              <router-link-->
              <!--                  :to="{-->
              <!--                  name: 'visit-detail',-->
              <!--                  params: { id: row.item.id },-->
              <!--                  query: { parents_url: 'visits' }-->
              <!--                }"-->
              <!--                  v-slot="{ href, route, navigate }"-->
              <!--                  custom-->
              <!--              >-->
              <!--                <b-button-->
              <!--                    class="mr-1"-->
              <!--                    :href="href"-->
              <!--                    @click="navigate"-->
              <!--                    variant="primary"-->
              <!--                    size="sm"-->
              <!--                    v-b-tooltip.hover-->
              <!--                    title="Visit Action"-->
              <!--                >-->
              <!--                  <i class="fas fa-running"></i>-->
              <!--                </b-button>-->
              <!--              </router-link>-->

              <!--              <b-button-->
              <!--                  v-if="hasCheckIn(row.item) && !hasCheckOut(row.item)"-->
              <!--                  size="sm"-->
              <!--                  v-b-modal.form-update-user-->
              <!--                  v-b-tooltip.hover-->
              <!--                  title="Revert Check In"-->
              <!--                  @click="revertCheckIn(row.item)"-->
              <!--                  class="mr-1"-->
              <!--              >-->
              <!--                <i class="fas fa-undo-alt"></i>-->
              <!--              </b-button>-->

              <!--              <b-button-->
              <!--                  v-if="!hasCheckIn(row.item) && !hasCheckOut(row.item)"-->
              <!--                  size="sm"-->
              <!--                  v-b-modal.form-update-user-->
              <!--                  v-b-tooltip.hover-->
              <!--                  title="Check In"-->
              <!--                  @click="handleCheckIn(row.item)"-->
              <!--                  class="mr-1"-->
              <!--              >-->
              <!--                <i class="fas fa-user-check"></i>-->
              <!--              </b-button>-->

              <!--              &lt;!&ndash;              <b-button&ndash;&gt;-->
              <!--              &lt;!&ndash;                v-if="hasCheckIn(row.item) && hasCheckOut(row.item)"&ndash;&gt;-->
              <!--              &lt;!&ndash;                size="sm"&ndash;&gt;-->
              <!--              &lt;!&ndash;                v-b-modal.form-update-user&ndash;&gt;-->
              <!--              &lt;!&ndash;                v-b-tooltip.hover&ndash;&gt;-->
              <!--              &lt;!&ndash;                title="Revert Check Out"&ndash;&gt;-->
              <!--              &lt;!&ndash;                @click="revertCheckOut(row.item)"&ndash;&gt;-->
              <!--              &lt;!&ndash;                class="mr-1"&ndash;&gt;-->
              <!--              &lt;!&ndash;              >&ndash;&gt;-->
              <!--              &lt;!&ndash;                <i class="fas fa-undo-alt"></i>&ndash;&gt;-->
              <!--              &lt;!&ndash;              </b-button>&ndash;&gt;-->

              <!--              <b-button-->
              <!--                  v-if="hasCheckIn(row.item) && !hasCheckOut(row.item)"-->
              <!--                  variant="danger"-->
              <!--                  size="sm"-->
              <!--                  v-b-modal.form-update-user-->
              <!--                  v-b-tooltip.hover-->
              <!--                  title="Check Out"-->
              <!--                  @click="handleCheckOut(row.item)"-->
              <!--                  class="mr-1"-->
              <!--              >-->
              <!--                <i class="fas fa-sign-out-alt"></i>-->
              <!--              </b-button>-->
            </template>
            <!--            <template #row-details="row">-->
            <!--              <b-card bg-variant="secondary">-->
            <!--                <div class="d-flex justify-content-start align-items-center">-->
            <!--                  <div class="d-flex w-20">-->
            <!--                    <div>Visit ID</div>-->
            <!--                  </div>-->
            <!--                  <div>: {{ row.item.id }}</div>-->
            <!--                </div>-->

            <!--                <div class="d-flex justify-content-start align-items-center">-->
            <!--                  <div class="d-flex w-20">-->
            <!--                    <div>Registration Type</div>-->
            <!--                  </div>-->
            <!--                  <div>: {{ row.item.registration_type | humanizeText }}</div>-->
            <!--                </div>-->

            <!--                <div class="d-flex justify-content-start align-items-center">-->
            <!--                  <div class="d-flex w-20">-->
            <!--                    <div>Visit Type</div>-->
            <!--                  </div>-->
            <!--                  <div>: {{ row.item.visit_type | humanizeText }}</div>-->
            <!--                </div>-->

            <!--                <div class="d-flex justify-content-end align-items-center">-->
            <!--                  <b-button variant="light" size="sm" @click="row.toggleDetails"-->
            <!--                    >Hide-->
            <!--                  </b-button>-->
            <!--                </div>-->
            <!--              </b-card>-->
            <!--            </template>-->
          </b-table>
        </div>
        <div class="d-flex justify-content-between" v-if="!busy && !search">
          <div class="d-flex">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              @change="handlePerPageChange"
            ></b-form-select>
            <div class="d-flex w-100">Per Page</div>
          </div>

          <b-pagination
            size="sm"
            class="d-flex"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  email,
  maxLength,
  minLength,
  required
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import _ from "lodash";
import jQuery from "jquery";

let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

export default {
  components: {
    DateRangePicker,
    Multiselect
  },
  data: function () {
    let yesterday = moment().subtract(1, "days").toDate();
    yesterday.setHours(0);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);
    yesterday.setMilliseconds(0);

    let today = moment().toDate();
    return {
      visit_data: [],
      visitList: [],
      visitSearchList: [],
      company: [],
      selected: [],
      selectMode: "multi",
      visitTableFields: [
        {
          key: "selected",
          label: ""
        },
        {
          key: "index",
          label: "No.",
          sortDirection: "desc",
          thStyle: { width: "3%" }
        },
        {
          key: "visit_code",
          label: "Visitation Code",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "hosts",
          label: "Hosts",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "start_date_time",
          label: "Start Datetime",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        // {
        //   key: "end_date_time",
        //   label: "End Datetime",
        //   sortable: true,
        //   sortDirection: "desc",
        //   thStyle: { width: "auto" }
        // },
        {
          key: "visitors",
          label: "Visitors",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "additional_metadata.check_in_time",
          label: "Check In Datetime",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        // {
        //   key: "additional_metadata.check_out_time",
        //   label: "Check Out Datetime",
        //   sortable: true,
        //   sortDirection: "desc",
        //   thStyle: { width: "auto" }
        // },
        {
          key: "status",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        { key: "actions", label: "Actions" }
      ],
      sortDesc: false,
      sortBy: "id",
      sortDirection: "desc",
      searchTerms: "",
      busy: false,
      // pagination
      totalRows: 0,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15],

      filter: null,
      filterOn: ["status"],
      search: false,
      searchForm: {
        keyword: null,
        status: [],
        dateRange: {
          startDate: null,
          endDate: null
        }
      },
      options_visit_status: []
    };
  },
  validations: {
    searchForm: {
      keyword: { minLength: minLength(3) }
    }
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    getVisits() {
      return this.$store.getters["visits/getVisits"](null);
    }
  },
  watch: {
    $route: function () {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 15;
      this.getVisitList();
    },
    getVisits: function (n, o) {
      this.visitList = n;
    }
  },
  created() {
    this.currentPage = this.$route.query.page
      ? parseInt(this.$route.query.page)
      : 1;
    this.perPage = this.$route.query.per_page
      ? parseInt(this.$route.query.per_page)
      : 15;
  },
  mounted: function () {
    this.fetchCompany(); // call all company
    this.getVisitList();
    this.getStatusList();
  },
  methods: {
    //status
    getStatusList() {
      let $this = this;
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/visit/all/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let validUrl = "";
          let result = data.result;
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;
                break outer_loop;
              }
            }
          }

          if (
            result[validUrl]["visits"] != undefined &&
            Array.isArray(result[validUrl]["visits"])
          ) {
            let options_visit_status = [];
            let array = [];
            let object = {
              key: null,
              value: null
            };
            let isNotExistYet = false;
            let status = null;
            $.each(result[validUrl]["visits"], function (i, el) {
              if ($.inArray(el.status, array) === -1) {
                array.push(el.status);
                isNotExistYet = true;
              }
              if (isNotExistYet) {
                isNotExistYet = false;
                status = el.status == "CANCELLED" ? "REVOKED" : el.status;
                object = {
                  key: status,
                  value: status
                };
                options_visit_status.push(object);
              }
            });

            this.options_visit_status = options_visit_status;
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err,
            message_type: "danger"
          });
        });
    },
    //visit
    getVisitList() {
      this.busy = true;
      this.search = false;
      this.visitList = [];
      this.visit_data = [];

      let $this = this;
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/visit/all/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL, {
          page_index: $this.currentPage,
          page_size: $this.perPage
        })
        .then((data) => {
          let validUrl = "";
          let result = data.result;
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;
                break outer_loop;
              }
            }
          }

          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl]["visits"])
          ) {
            for (let key2 in result[validUrl]["visits"]) {
              if (result[validUrl]["visits"][key2].status == "CANCELLED") {
                result[validUrl]["visits"][key2].status = "REVOKED";
              }
              $this.visitList.push(result[validUrl]["visits"][key2]);

              if (this.$route?.query.keyword) {
                this.searchForm.keyword = this.$route.query.keyword;
                if (this.searchForm.keyword == this.$route.query.keyword) {
                  this.visitList = [];
                  this.search = true;
                }
                this.getSearchData();
              }
            }
          } else {
            $this.visitList = [];
          }

          if (result[validUrl]["total"] !== undefined) {
            $this.totalRows = parseInt(result[validUrl]["total"]);
          }

          if (result[validUrl]["page"] !== undefined) {
            $this.currentPage = parseInt(result[validUrl]["page"]);
          }

          if (result[validUrl]["size"] !== undefined) {
            $this.perPage = parseInt(result[validUrl]["size"]);
          }

          $this.busy = false;
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err,
            message_type: "danger"
          });
        });
    },

    fetchCompany() {
      let $this = this;
      let API_URL = this.getAPIServerURL + "/api/visitorservice/company/all/";
      const client = this.$root.getAjaxFetchClient();

      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          if (result !== undefined) {
            outer_loop: for (let key in result) {
              $this.company.push(result[key]);
              break outer_loop;
            }
          }
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err,
            message_type: "danger"
          });
        });
    },
    //search
    getSearchData: function () {
      let $this = this;
      $this.busy = true;
      $this.visitList = [];
      let startDateRange = $this.searchForm?.dateRange?.startDate ?? null;
      let endDateRange = $this.searchForm?.dateRange?.endDate ?? null;
      let keyword = $this.searchForm?.keyword ?? null;
      let status = [];
      for (let index in $this.searchForm?.status) {
        status.push(
          $this.searchForm?.status?.[index].key == "REVOKED"
            ? "CANCELLED"
            : $this.searchForm?.status?.[index].key ?? null
        );
      }

      let start_date =
        startDateRange != null
          ? moment(startDateRange).format("YYYY-MM-DD")
          : null;
      let end_date =
        endDateRange != null ? moment(endDateRange).format("YYYY-MM-DD") : null;
      let start_time =
        startDateRange != null
          ? moment(startDateRange).format("HH:mm:ss")
          : null;
      let end_time =
        endDateRange != null ? moment(endDateRange).format("HH:mm:ss") : null;

      let formBody = {};
      formBody = {
        start_date: start_date,
        end_date: end_date,
        start_time: start_time,
        end_time: end_time,
        keyword: keyword,
        status: status
      };
      //console.log("formBody", formBody);

      let API_URL = $this.getAPIServerURL + "/api/visitorservice/visit/search/";

      try {
        const client = $this.$root.getAjaxFetchClient();
        client
          .postRequest(API_URL, formBody)
          .then((data) => {
            this.visitList = [];
            for (let key in data?.result) {
              for (let key2 in data?.result?.[key]) {
                for (let key3 in data?.result?.[key][key2]) {
                  let status = data?.result[key][key2][key3].status;
                  data.result[key][key2][key3].status =
                    status == "CANCELLED"
                      ? "REVOKED"
                      : data.result[key][key2][key3].status;
                  let visit_start_date =
                    data.result[key][key2][key3]["visit_start_date"];
                  let visit_end_date =
                    data.result[key][key2][key3]["visit_end_date"];
                  $this.visitList =
                    formBody.start_date >= visit_start_date &&
                    formBody.end_date >= visit_end_date
                      ? data.result[key][key2]
                      : formBody.start_date <= visit_start_date &&
                        formBody.end_date >= visit_end_date
                      ? data.result[key][key2]
                      : formBody.keyword != null || formBody.status != null
                      ? data.result[key][key2]
                      : [];

                  $this.search = true;
                  $this.busy = false;

                  for (let kay in $this.visitList) {
                    for (let kay1 in $this.visitList[kay].hosts) {
                      $this.visitList[kay].hosts[kay1].company = null;
                      for (let kay2 in $this.company) {
                        for (let kay3 in $this.company[kay2]) {
                          if (
                            $this.visitList[kay].hosts[kay1].company_id ==
                            $this.company[kay2][kay3].id
                          ) {
                            $this.visitList[kay].hosts[kay1].company =
                              $this.company[kay2][kay3];
                            break;
                          }
                        }
                      }
                    }
                  }
                }

                if (this.busy == true) {
                  this.visitList = [];
                  this.search = true;
                  this.busy = false;
                }
              }
            }
            this.$v.$reset();
          })
          .catch((err) => {
            this.busy = false;
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err,
              message_type: "danger"
            });
          });
      } catch (e) {
        console.log("e", e);
      }
    },
    //reset
    resetSearch: function () {
      let yesterday = moment().subtract(1, "days").toDate();
      yesterday.setHours(0);
      yesterday.setMinutes(0);
      yesterday.setSeconds(0);
      yesterday.setMilliseconds(0);

      this.$route.query.keyword = "";
      this.search = false;
      this.searchForm = {
        keyword: "",
        dateRange: {
          startDate: null,
          endDate: null
        }
      };
      this.$v.$reset();
      this.$router.push({
        name: this.$route.query.parents_url
      });
      //location.reload();
    },

    /// check In
    checkInRevert(visit_data) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visit/?visit_id=" +
        visit_data.id;
      const client = $this.$root.getAjaxFetchClient();

      client
        .putRequest(API_URL, visit_data)
        .then(() => {
          for (let key in $this.visitList) {
            if (visit_data.id == $this.visitList[key].id) {
              $this.visitList[key].additional_metadata["check_in_time"] =
                visit_data.additional_metadata.check_in_time;
              let temp = $this.visitList[key];
              $this.$set($this.visitList, key, temp);
            }
          }
          this.hasCheckIn(visit_data);
        })
        .catch((err) => {
          console.log("err", err);
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.detail,
          //   message_type: "danger"
          // });
        });
    },
    checkIn(visit_data) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visit/check-in/?visit_id=" +
        visit_data.id;

      let metadata = visit_data.additional_metadata;
      metadata = {
        ...metadata,
        check_in_time: moment().unix()
      };
      let visit_data2 = {
        ...visit_data,
        additional_metadata: metadata
      };

      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, null)
        .then(() => {
          for (let key in $this.visitList) {
            if (visit_data2.id == $this.visitList[key].id) {
              $this.visitList[key].additional_metadata["check_in_time"] =
                visit_data2.additional_metadata.check_in_time;
              let temp = $this.visitList[key];
              $this.$set($this.visitList, key, temp);
            }
          }
        })

        .catch((err) => {});
    },
    handleCheckIn(visit_data) {
      let $this = this;
      this.$bvModal
        .msgBoxConfirm('Confirm "Check-In" ?', {
          centered: true
        })
        .then((value) => {
          if (value) {
            $this.checkIn(visit_data);
          }
        })
        .catch((err) => {
          console.log("err", err);
          // An error occurred
        });
    },
    hasCheckIn(visit_data) {
      let flag = null;
      if (visit_data.additional_metadata.check_in_time != null) {
        flag = true;
      } else {
        flag = false;
      }
      return flag;
    },
    revertCheckIn(visit_data) {
      visit_data.additional_metadata.check_in_time = null;
      this.checkInRevert(visit_data);
    },

    // check out
    checkOutRevert(visit_data) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visit/?visit_id=" +
        visit_data.id;

      visit_data = {
        ...visit_data,
        status: "APPROVED"
      };

      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, visit_data)
        .then(() => {
          for (let key in $this.visitList) {
            if (visit_data.id == $this.visitList[key].id) {
              $this.visitList[key].additional_metadata["check_out_time"] =
                visit_data.additional_metadata.check_out_time;

              $this.visitList[key].status = "APPROVED";

              let temp = $this.visitList[key];
              $this.$set($this.visitList, key, temp);
            }
          }

          this.hasCheckOut(visit_data);
        })
        .catch((err) => {
          console.log("err", err);
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err,
          //   message_type: "danger"
          // });
        });
    },
    checkOut(item, value) {
      this.busy = true;
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visit/check-out/?visit_id=" +
        item.id;

      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, null)
        .then(() => {
          let metadata = item.additional_metadata;
          metadata = {
            ...metadata,
            check_out_time: moment().unix()
          };
          let visit_data = {
            ...item,
            additional_metadata: metadata,
            status: "COMPLETED"
          };

          this.$store.dispatch("visit/updateCheckout", visit_data);
          //console.log("this.selected.length", this.selected.length);
          switch (value) {
            case "array":
              if (this.selected.length != 0) {
                this.$bvModal
                  .msgBoxOk(
                    "Total " +
                      this.selected.length +
                      " visitors have successful checked-out.",
                    {
                      centered: true
                    }
                  )
                  .then((value) => {
                    if (value) {
                      this.clearSelected();
                    }
                  });
              }
              break;
            default:
              for (let key in this.visitList) {
                if (visit_data.id == this.visitList[key].id) {
                  this.visitList[key].additional_metadata["check_out_time"] =
                    visit_data.additional_metadata.check_out_time;

                  this.visitList[key].status = "COMPLETED";

                  let temp = this.visitList[key];
                  this.$set(this.visitList, key, temp);
                }
              }
          }

          let startDateRange = $this.searchForm?.dateRange?.startDate ?? null;
          let endDateRange = $this.searchForm?.dateRange?.endDate ?? null;
          let keyword = $this.searchForm?.keyword ?? null;
          let status =
            $this.searchForm?.status?.key == "REVOKED"
              ? "CANCELLED"
              : $this.searchForm?.status?.key ?? null;

          if (
            startDateRange == null &&
            endDateRange == null &&
            keyword == null &&
            status == null
          ) {
            this.getVisitList();
          } else {
            this.getSearchData();
          }
        })
        .catch((err) => {
          console.log("err", err);
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err,
          //   message_type: "danger"
          // });
        });
    },
    handleCheckOut(visit_data) {
      let $this = this;
      this.$bvModal
        .msgBoxConfirm(
          "This will remove all the access for this visitor. This action is immutable.",
          {
            title: 'Confirm "Check-Out" ?',
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            this.checkOut(visit_data, "object");
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    hasCheckOut(visit_data) {
      let flag = null;
      if (visit_data.additional_metadata.check_out_time != null) {
        flag = true;
      } else {
        flag = false;
      }
      return flag;
    },
    revertCheckOut(visit_data) {
      visit_data.additional_metadata.check_out_time = null;
      this.checkOutRevert(visit_data);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.visitSearchList = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    filterTable(row, filter) {
      for (let key4 in filter) {
        if (row.status.indexOf(filter[key4].key) !== -1) {
          return true;
        }
      }
      return false;
    },

    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2);
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    setCheckOut() {
      this.$bvModal
        .msgBoxConfirm(
          "This will remove all the access for this visitor. This action is immutable. Total " +
            this.selected.length +
            " visitors selected.",
          {
            title: 'Confirm "Check-Out" ?',
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            for (let index in this.selected) {
              this.checkOut(this.selected[index], "array");
            }
          } else {
            this.clearSelected();
          }
        })
        .catch((err) => {
          // An error occurred
          console.log("err", err);
        });
    },
    //select row
    onRowSelected(items) {
      let $this = this;
      let index = 0;
      let isPending = false;
      let visit_list =
        this.searchTerms === "" ? this.visitList : this.visitSearchList;

      for (let key6 in items) {
        for (let key7 in visit_list) {
          if (
            (items[key6].status === "PENDING" ||
              items[key6].status === "REVOKED" ||
              items[key6].status === "COMPLETED" ||
              items[key6].status === "DECLINED" ||
              ((items[key6].status === "EXPIRED" ||
                items[key6].status === "APPROVED") &&
                items[key6].additional_metadata.check_in_time == undefined)) &&
            items[key6].visit_code === visit_list[key7].visit_code
          ) {
            isPending = true;
            index = parseInt(key7);
            $this.$refs.selectableTable.unselectRow(index);
          }
        }
      }
      this.selected = !isPending ? items : null;
    },
    // pagination
    handlePageChange(page) {
      //console.log("updated page", page);
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      //console.log("updated pageSize", pageSize);

      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          per_page: parseInt(pageSize)
        }
      });
    },
    //validate
    validateFormSearch(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.searchForm[field];
        result = $dirty ? !$error : null;
        return result;
      }
    }
    /* onContext(ctx) {
      // The following will be an empty string until a valid date is entered
      this.searchForm.selected = ctx.selectedYMD;
      console.log(" this.searchForm.selected ", this.searchForm.selected);
    }, */
  },
  filters: {
    visitorName(value) {
      let visitor = value.visitors;
      try {
        for (let key in visitor) {
          return visitor[key].name;
        }
      } catch (e) {
        return "-";
      }
    }
  }
};
</script>

<style lang="scss">
.company-font {
  font-size: var(--font-size-medium);
  //font-size: medium;
}

.host-font {
  font-size: var(--font-size-normal);
}

.calendars {
  background: var(--colour-nav-bg);
}

.drp-buttons {
  background: var(--colour-nav-bg);
}

.calendar-table {
  color: var(--colour-overlay-gray-dark);
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
